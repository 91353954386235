import gear from './gear.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={gear} className="App-logo" alt="logo" />
        <p>
          Just like our Projects, this website is under-construction
        </p>
        <p>Email us at contact@ykengineers.in</p>
        
      </header>
    </div>
  );
}

export default App;
